<template>
  <main>
    <b-row>
      <b-col sm="12" md="3">
    <b-card title="Rekap Slip Gaji">
      <b-col>
      <b-row>
        <b-col cols="12">
        <b-form-group label="Bulan" label-for="v-Bulan">
                    <!-- <flat-pickr v-model="bulan" class="form-control"
                          :config="{dateFormat: 'n' }" /> -->
                          <b-form-select v-model="bulan"
                        :options="optbulan"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
        <b-form-group label="Tahun" label-for="v-Tahun">
                    <!-- <flat-pickr v-model="tahun" class="form-control"
                          :config="{dateFormat: 'Y' }" /> -->
                          <b-form-input v-model="tahun" id="v-tahun"
                        placeholder="tahun" type="number" />
                  </b-form-group>
                </b-col>
                  <b-button v-b-tooltip.hover.right="'Print / Cetak'" @click.prevent="unduhrekap"
            class="mr-50" variant="warning">
                <feather-icon icon="PrinterIcon" /> Print Rekap Gaji
                  </b-button>
                  <b-alert class="mt-1"
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        Keterangan
      </h4>
      <div class="alert-body">
        <span>Pilih Bulan dan Tahun untuk print "Slip Gaji" per karyawan dan "Rekap Gaji" seluruh karyawan</span>
      </div>
    </b-alert>
      </b-row>
    </b-col>
    </b-card>
  </b-col>
    <b-col>
      
  <b-card-actions action-collapse ref="refreshCard" title="Daftar Penggajian">
    <b-row >
      <b-col class="my-1" v-if="allowCreate()">
        <b-button @click.prevent="addWizard" variant="primary">
          <feather-icon icon="PlusIcon" class="" />
          Penggajian
        </b-button>
      </b-col>
    </b-row>
    <b-modal v-model="showModalWizard" id="modal-tambah" size="xl" ok-title="Tutup" ok-variant="secondary" ok-only centered
      title="Form">
      <form-wizard
        color="#ff9801"
        :title="null"
        :subtitle="null"
        finish-button-text="Simpan"
        back-button-text="Sebelumnya"
        next-button-text="Selanjutnya"
        @on-complete="submit"
        class="wizard-vertical mb-3"
        >
        <!-- data no surat -->
        <tab-content title="Pokok" :before-change="validationForm">
          <validation-observer ref="form">
            <b-row>
          <!-- karyawan -->
          <b-col cols="6">
                  <b-form-group label="Karyawan" label-for="v-karyawan">
                    <validation-provider #default="{ errors }" name="karyawan" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.id_karyawan"
                        :options="optkaryawan" label="Karyawan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- pokok -->
                <b-col cols="6">
                  <b-form-group label="Gaji Pokok" label-for="v-pokok">
                    <validation-provider #default="{ errors }" name="pokok" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.pokok" id="v-pokok"
                        placeholder="pokok" @keyup="form.pokok = formatRupiah(form.pokok)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- makan -->
                <b-col cols="6">
                  <b-form-group label="Makan" label-for="v-makan">
                    <validation-provider #default="{ errors }" name="makan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.makan" id="v-makan"
                        placeholder="makan" @keyup="form.makan = formatRupiah(form.makan)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- transportasi -->
                <b-col cols="6">
                  <b-form-group label="Transportasi" label-for="v-transportasi">
                    <validation-provider #default="{ errors }" name="transportasi" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.transportasi" id="v-transportasi"
                        placeholder="transportasi" @keyup="form.transportasi = formatRupiah(form.transportasi)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_jabatan -->
                <b-col cols="6">
                  <b-form-group label="Tunjangan Jabatan" label-for="v-tun_jabatan">
                    <validation-provider #default="{ errors }" name="tun_jabatan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_jabatan" id="v-tun_jabatan"
                        placeholder="tun_jabatan" @keyup="form.tun_jabatan = formatRupiah(form.tun_jabatan)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_kinerja -->
                <b-col cols="6">
                  <b-form-group label="Tunjangan Kinerja" label-for="v-tun_kinerja">
                    <validation-provider #default="{ errors }" name="tun_kinerja" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_kinerja" id="v-tun_kinerja"
                        placeholder="tun_kinerja" @keyup="form.tun_kinerja = formatRupiah(form.tun_kinerja)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lama_kerja -->
                <b-col cols="6">
                  <b-form-group label="Lama Kerja (bulan)" label-for="v-lama_kerja">
                    <validation-provider #default="{ errors }" name="lama_kerja" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lama_kerja" id="v-lama_kerja"
                        placeholder="lama_kerja" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- bonus -->
                <b-col cols="6">
                  <b-form-group label="Bonus" label-for="v-bonus">
                    <validation-provider #default="{ errors }" name="bonus" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.bonus" id="v-bonus"
                        placeholder="bonus" @keyup="form.bonus = formatRupiah(form.bonus)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
          </validation-observer>
        </tab-content>
        <!-- / -->

        <!-- List barang -->
        <tab-content title="Lembur">
          <validation-observer ref="form">
            <b-row>
          <!-- lembur -->
          <b-col cols="6">
                  <b-form-group label="Lembur" label-for="v-lembur">
                    <validation-provider #default="{ errors }" name="lembur" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lembur" id="v-lembur"
                        placeholder="lembur" @keyup="form.lembur = formatRupiah(form.lembur)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lembur_libur -->
                <b-col cols="6">
                  <b-form-group label="Lembur di hari libur" label-for="v-lembur_libur">
                    <validation-provider #default="{ errors }" name="lembur_libur" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lembur_libur" id="v-lembur_libur"
                        placeholder="lembur_libur" @keyup="form.lembur_libur = formatRupiah(form.lembur_libur)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_hari_raya -->
                <b-col cols="6">
                  <b-form-group label="Tunjangan Hari Raya" label-for="v-tun_hari_raya">
                    <validation-provider #default="{ errors }" name="tun_hari_raya" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_hari_raya" id="v-tun_hari_raya"
                        placeholder="tun_hari_raya" @keyup="form.tun_hari_raya = formatRupiah(form.tun_hari_raya)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
          </validation-observer>
        </tab-content>

        <tab-content title="Potongan">
          <validation-observer ref="lastForm">
            <b-row>
              <!-- bpjs_kesehatan -->
              <b-col cols="6">
                  <b-form-group label="BPJS Kesehatan" label-for="v-bpjs_kesehatan">
                    <validation-provider #default="{ errors }" name="bpjs_kesehatan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.bpjs_kesehatan" id="v-bpjs_kesehatan"
                        placeholder="bpjs_kesehatan" @keyup="form.bpjs_kesehatan = formatRupiah(form.bpjs_kesehatan)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- bpjs_ketenagakerjaan -->
                <b-col cols="6">
                  <b-form-group label="BPJS Ketenagakerjaan" label-for="v-bpjs_ketenagakerjaan">
                    <validation-provider #default="{ errors }" name="bpjs_ketenagakerjaan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.bpjs_ketenagakerjaan" id="v-bpjs_ketenagakerjaan"
                        placeholder="bpjs_ketenagakerjaan" @keyup="form.bpjs_ketenagakerjaan = formatRupiah(form.bpjs_ketenagakerjaan)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- telat -->
                <b-col cols="6">
                  <b-form-group label="Telat" label-for="v-telat">
                    <validation-provider #default="{ errors }" name="telat" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.telat" id="v-telat"
                        placeholder="telat" @keyup="form.telat = formatRupiah(form.telat)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- cicilan -->
                <b-col cols="6">
                  <b-form-group label="Cicilan" label-for="v-cicilan">
                    <validation-provider #default="{ errors }" name="cicilan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.cicilan" id="v-cicilan"
                        placeholder="cicilan" @keyup="form.cicilan = formatRupiah(form.cicilan)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- / -->
      </form-wizard>
      </b-modal>
    <b-modal v-model="showModal" id="modal-s" size="xl" ok-title="Tutup" ok-variant="secondary" ok-only centered
      title="Form">
      <validation-observer ref="formKaryawan">
        <b-row>
          <b-col cols="6">
            <b-form>
              <b-row>
                <div class="d-flex">
                  <feather-icon icon="UserIcon" size="19" />
                  <h6 class="mb-1 ml-50">Data Penggajian</h6>
                </div>
              </b-row>
              <b-row>

                <!-- karyawan -->
                <b-col cols="12">
                  <b-form-group label="Karyawan" label-for="v-karyawan">
                    <validation-provider #default="{ errors }" name="karyawan" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.id_karyawan"
                        :options="optkaryawan" label="Karyawan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- pokok -->
                <b-col cols="12">
                  <b-form-group label="Gaji Pokok" label-for="v-pokok">
                    <validation-provider #default="{ errors }" name="pokok" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.pokok" id="v-pokok"
                        placeholder="pokok" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_jabatan -->
                <b-col cols="12">
                  <b-form-group label="Tunjangan Jabatan" label-for="v-tun_jabatan">
                    <validation-provider #default="{ errors }" name="tun_jabatan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_jabatan" id="v-tun_jabatan"
                        placeholder="tun_jabatan" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_kinerja -->
                <b-col cols="12">
                  <b-form-group label="Tunjangan Kinerja" label-for="v-tun_kinerja">
                    <validation-provider #default="{ errors }" name="tun_kinerja" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_kinerja" id="v-tun_kinerja"
                        placeholder="tun_kinerja" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lama_kerja -->
                <b-col cols="12">
                  <b-form-group label="Lama Kerja" label-for="v-lama_kerja">
                    <validation-provider #default="{ errors }" name="lama_kerja" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lama_kerja" id="v-lama_kerja"
                        placeholder="lama_kerja" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- makan -->
                <b-col cols="12">
                  <b-form-group label="Makan" label-for="v-makan">
                    <validation-provider #default="{ errors }" name="makan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.makan" id="v-makan"
                        placeholder="makan" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
          <b-col cols="6">
            <b-form>
              <b-row>
                <div class="d-flex">
                  <feather-icon icon="UserIcon" size="19" />
                  <h6 class="mb-1 ml-50">-</h6>
                </div>
              </b-row>
              <b-row>
                <!-- lembur -->
                <b-col cols="12">
                  <b-form-group label="Lembur" label-for="v-lembur">
                    <validation-provider #default="{ errors }" name="lembur" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lembur" id="v-lembur"
                        placeholder="lembur" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lembur_libur -->
                <b-col cols="12">
                  <b-form-group label="Lembur di hari libur" label-for="v-lembur_libur">
                    <validation-provider #default="{ errors }" name="lembur_libur" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.lembur_libur" id="v-lembur_libur"
                        placeholder="lembur_libur" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- tun_hari_raya -->
                <b-col cols="12">
                  <b-form-group label="Tunjangan Hari Raya" label-for="v-tun_hari_raya">
                    <validation-provider #default="{ errors }" name="tun_hari_raya" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.tun_hari_raya" id="v-tun_hari_raya"
                        placeholder="tun_hari_raya" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- bpjs_kesehatan -->
                <b-col cols="12">
                  <b-form-group label="BPJS Kesehatan" label-for="v-bpjs_kesehatan">
                    <validation-provider #default="{ errors }" name="bpjs_kesehatan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.bpjs_kesehatan" id="v-bpjs_kesehatan"
                        placeholder="bpjs_kesehatan" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- bpjs_ketenagakerjaan -->
                <b-col cols="12">
                  <b-form-group label="BPJS Ketenagakerjaan" label-for="v-bpjs_ketenagakerjaan">
                    <validation-provider #default="{ errors }" name="bpjs_ketenagakerjaan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.bpjs_ketenagakerjaan" id="v-bpjs_ketenagakerjaan"
                        placeholder="bpjs_ketenagakerjaan" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- telat -->
                <b-col cols="12">
                  <b-form-group label="Telat" label-for="v-telat">
                    <validation-provider #default="{ errors }" name="telat" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.telat" id="v-telat"
                        placeholder="telat" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- cicilan -->
                <b-col cols="12">
                  <b-form-group label="Cicilan" label-for="v-cicilan">
                    <validation-provider #default="{ errors }" name="cicilan" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.cicilan" id="v-cicilan"
                        placeholder="cicilan" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="submit"
              >
                {{ label || "Submit" }}
              </b-button>
            </b-col>
            </b-form>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(jk)="data">
            <b-badge :variant="jk[1][data.value]">
              {{ jk[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(id_karyawan)="data">
            <b-badge variant="dark">
              {{ getJabatanName(data.item) }}
            </b-badge>
          </template>

          <template #cell(total)="{ item }">
                  <strong
                    >Rp
                    {{
                      item.total > 0 ? formatRupiah(item.total) : item.total
                    }}</strong
                  >
                </template>
                <template #cell(lama_kerja)="{ item }">
                  <strong
                    >Rp
                    {{
                      item.lama_kerja > 0 ? formatRupiah(item.lama_kerja) : item.lama_kerja
                    }}</strong
                  >
                </template>
          <template #cell(actions)="row">
            <b-button v-b-tooltip.hover.right="'Print / Cetak'" size="sm" @click.prevent="unduhbop(row.item)"
            class="mr-1" variant="outline-warning">
                <feather-icon icon="PrinterIcon"></feather-icon>
              </b-button>
            <b-button v-if="allowUpdate()" v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)"
              class="mr-1" variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="allowDelete()" v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)"
              class="mr-1" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only @hide="true">
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card-actions>
</b-col>
    </b-row>
</main>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import {
  BTable, BFormFile,BAlert,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    flatPickr,BAlert,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BModal, BFormFile,
    BFormDatepicker,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCard,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
    FormWizard,
    TabContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      email,
      confirmed,
      password,
      showModal: false,
      showModalWizard: false,
      label: "Submit",
      bulan: this.getBulan(),
      tahun: this.getTahun(),
      form: {
        id_karyawan: null,
        pokok: 3750000,
        tun_jabatan: 0,
        tun_kinerja: 0,
        lama_kerja: 0,
        tun_jabatan: 0,
        makan: 0,
        bonus: 0,
        transportasi: 0,
        lembur: 0,
        lembur_libur: 0,
        tun_hari_raya: 0,
        bpjs_kesehatan: 0,
        bpjs_ketenagakerjaan: 0,
        telat: 0,
        cicilan: 0,
      },
      optkaryawan: [],
      optbulan: [
        { value: 1, text: "Januari" },
        { value: 2, text: "Februari" },
        { value: 3, text: "Maret" },
        { value: 4, text: "April" },
        { value: 5, text: "Mei" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "Agustus" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Desember" },
      ],
      value: "",
      formatted: "",
      selected: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "No",
        },
        { key: "karyawan.nama_lengkap", label: "Nama", sortable: true },
        { key: "karyawan.jabatan.nama_jabatan", label: "Jabatan", sortable: true },
        { key: "total", label: "Total Gaji", sortable: true },
        // { key: "nip", label: "NIP", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      jk: [
        {
          1: "Laki-laki",
          2: "Perempuan",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      agama: [
        {
          1: "Islam",
          2: "Kristen",
          3: "Katolik",
          4: "Hindu",
          5: "Buddha",
          6: "Khonghucu",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_perkawinan: [
        {
          1: "Lajang",
          2: "Menikah",
          3: "Duda/Janda",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_karyawan: [
        {
          1: "Aktif",
          2: "Tidak Aktif",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_sp: [
        {
          0: "Tidak mempunyai SP",
          1: "SP 1",
          2: "SP 2",
          3: "SP 3",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getData();
    this.getDataKaryawan();
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async unduhbop(item) {
      try {
      this.loading = true
      const bulan = this.bulan < 10 ? `0${this.bulan}` : this.bulan
      const response = await this.$store.dispatch("penggajian/printSlipGaji", {
        id_karyawan: item.karyawan.id,
        bulan,
        tahun: this.tahun,
      });
      this.loading = false
      window.open(URL.createObjectURL(response))
      this.displaySuccess({
                  message: "Data tersedia",
                });
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        this.displayInfo({
                  message: "Data slip gaji tidak ditemukan",
                });
        return false
      }
    },
    async unduhrekap(item) {
      try {
      this.loading = true
      const bulan = this.bulan < 10 ? `0${this.bulan}` : this.bulan
      const response = await this.$store.dispatch("penggajian/printRekapGaji", {
        bulan,
        tahun: this.tahun,
      });
      this.loading = false
      window.open(URL.createObjectURL(response))
      this.displaySuccess({
                  message: "Data tersedia",
                });
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        this.displayInfo({
                  message: "Data slip gaji tidak ditemukan",
                });
        return false
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    getJabatanName(data) {
      let find = this.id_karyawan.find((item) => item.id == data.id_karyawan);
      return find ? find.nama_jabatan : "-";
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      if (item.jabatan) {
        this.form.id_karyawan = item.jabatan.id;
      }
      this.showModalWizard = true;
    },
    add() {
      // this.resetForm();
      this.showModal = true;
    },
    addWizard() {
      this.id = null
      this.showModalWizard = true;
      // this.$bvModal.show('modal-tambah')
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data penggajian ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("penggajian/save", [item])
            .then(() => this.getData())
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    // resetForm() {
    //   this.id = null;
    //   this.form = {
    //     nama_lengkap: null,
    //     no_hp: null,
    //     email: null,
    //     nik: null,
    //     nip: null,
    //     alamat: null,
    //     id_karyawan: null,
    //     jk: null, //Di isi dengan 1 = Laki-Laki OR 2 = Perempuan
    //     agama: null, //Di isi dengan 1 = ISLAM OR 2 = KRISTEN OR 3 = KATOLIK OR 4 = HINDU OR 5 = BUDDHA OR 6 = KHONGHUCU
    //     status_perkawinan: null, // Di isi dengan 1 = Lajang OR 2 = Menikah OR 3 = Duda/Janda
    //     status_karyawan: null, //Di isi dengan 1 = Aktif Or 0 = Tidak Aktif
    //     status_sp: null, // Di isi dengan 0 = Tidak Mempunyai Sp Or 1 = Sp 1 Or 2 = Sp 2 Or 3 = Sp 3
    //     kampus_sekolah: "",
    //     pendidikan_terakhir: "",
    //     kualifikasi_pendidikan: "",
    //     masa_kerja: "",
    //     grade_karyawan: "",
    //     file_foto: "",
    //     tempat_lahir: "",
    //     tanggal_lahir: "", //Format 2022-01-22
    //   };
    // },
    submit() {
      this.$refs.lastForm.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }

          this.form.pokok = this.unFormatRupiah(this.form.pokok)
          this.form.makan = this.unFormatRupiah(this.form.makan)
          this.form.transportasi = this.unFormatRupiah(this.form.transportasi)
          this.form.tun_jabatan = this.unFormatRupiah(this.form.tun_jabatan)
          this.form.tun_kinerja = this.unFormatRupiah(this.form.tun_kinerja)
          this.form.bonus = this.unFormatRupiah(this.form.bonus)
          this.form.lembur = this.unFormatRupiah(this.form.lembur)
          this.form.lembur_libur = this.unFormatRupiah(this.form.lembur_libur)
          this.form.tun_hari_raya = this.unFormatRupiah(this.form.tun_hari_raya)
          this.form.bpjs_kesehatan = this.unFormatRupiah(this.form.bpjs_kesehatan)
          this.form.bpjs_ketenagakerjaan = this.unFormatRupiah(this.form.bpjs_ketenagakerjaan)
          this.form.telat = this.unFormatRupiah(this.form.telat)
          // this.form.cicilan = this.unFormatRupiah(this.form.cicilan)
          
          const payload = this.form;
          payload.file_foto = ''
          this.$store
            .dispatch("penggajian/save", [payload])
            .then(() => {
              this.id = null
              delete this.form.id
              this.label = "Submit";
              // this.resetForm();
              this.displaySuccess({
                message:"Data penggajian berhasil tersimpan"
              })
              this.showModalWizard = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    getData() {
      this.$store.dispatch("penggajian/getData", {}).then(() => {
        this.items = this.$store.state.penggajian.datas;
        this.totalRows = this.items.length;
      });
    },
    getDataKaryawan() {
      let payload = {}
      if (this.isCabang){
        payload.member_id = this.user.member_id
      }
      this.$store
        .dispatch("karyawan/getData", payload).then(() => {
        let hem = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
        hem.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optkaryawan = hem;
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>